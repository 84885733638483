.container {
  position: relative;
}

.ctas {
  position: absolute;
  bottom: 0;
  padding: 0 var(--spacing-md) var(--spacing-xl);
  width: 100%;
  a {
    min-width: 250px;
  }
}
